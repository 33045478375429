import Vue from 'vue'
import VueRouter from 'vue-router'
import HomeView from '../views/HomeView.vue'

import store from '../store' // vuex store

const ifNotAuthenticated = (to, from, next) => {
  if (!store.getters.isAuthenticated) {
    next()
    return
  }
  next('/')
}

const ifAuthenticated = (to, from, next) => {
  if (store.getters.isAuthenticated) {
    next()
    return
  }
  next('/login')
}

Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'home',
    beforeEnter: ifAuthenticated,
    component: HomeView
  }, {
    path: '/goodsorder',
    name: 'goodsorder',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/Goodsorder.vue')
  }, {
    path: '/goodsorderedit',
    name: 'goodsorderedit',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/GoodsorderEdit.vue'),
    //props: (route) => ({...route.params})
  }, {
    path: '/cashreceive',
    name: 'cashreceive',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/CashReceive.vue')
  }, {
    path: '/photo',
    name: 'photo',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/Photo.vue')
  }, {
    path: '/reports',
    name: 'reports',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/Reports.vue')
  }, {
    path: '/settings',
    name: 'settings',
    beforeEnter: ifAuthenticated,
    component: () => import('../views/Settings.vue')
  },
  {
    path: '/login',
    name: 'login',
    beforeEnter: ifNotAuthenticated,
    component: () => import('../views/Login.vue')
  },
/*
  {
    path: '/about',
    name: 'about',
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () => import('../views/AboutView.vue')
  }
*/
]

const router = new VueRouter({
  routes
})

export default router
