<template>
  <v-container>
        <v-card raised class="mt-3 pb-3">
          <v-card-title class="d-block text-center align-center">
            Кабинет куратора
          </v-card-title>
          <v-card-text class="py-1 text-center">
              <v-list nav dense>
                  <v-list-item two-line >
                    <v-list-item-content>
                      <v-list-item-title>{{ getProfileData('fio') }}<br/>{{ getProfileData('dgv') }}</v-list-item-title>
                    </v-list-item-content>
                  </v-list-item>
              </v-list>
          </v-card-text>
        </v-card>
  </v-container>
</template>


<script>

  import store from '../store'

  export default {
    name: 'HomePage',
    methods: {
      getProfileData(prop) {
        if(store.getters.getProfile.data && store.getters.getProfile.data.data)
          return store.getters.getProfile.data.data[prop];
      },

    }

  }
</script>
