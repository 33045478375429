import {
  AUTH_REQUEST,
  AUTH_ERROR,
  AUTH_SUCCESS,
  AUTH_LOGOUT
} from "../actions/auth";
import { USER_REQUEST } from "../actions/user";
import { REPORTS_REQUEST } from "../actions/report";
import apiCall from "../../utils/api";

const state = {
  token: localStorage.getItem("user-token-curator") || "",
  status: "",
  hasLoadedOnce: false
};

const getters = {
  isAuthenticated: state => !!state.token,
  authStatus: state => state.status
};

const actions = {
  [AUTH_REQUEST]: ({ commit, dispatch }, user) => {
    return new Promise((resolve, reject) => {
      commit(AUTH_REQUEST);
      apiCall({ method: "login", dgv: user.username, pass: user.password })
        .then(resp => {
          if(resp.data && resp.data.success) {
            localStorage.setItem("user-token-curator", resp.data.data.hkey);
            localStorage.setItem("idperson-curator", resp.data.data.idperson);

            let aData = localStorage.getItem("authData-curator");
            aData = aData?JSON.parse(aData):{};
            aData[resp.data.data.idperson] = {idperson: resp.data.data.idperson, hkey: resp.data.data.hkey, fio: resp.data.data.fio, dgv: resp.data.data.dgv};
            localStorage.setItem( "authData-curator", JSON.stringify(aData) );


            commit(AUTH_SUCCESS, resp);
            dispatch(USER_REQUEST);
            dispatch(REPORTS_REQUEST);
            resolve(resp);
          } else {
            commit(AUTH_ERROR, resp);
            localStorage.removeItem("user-token-curator");
            reject(resp);
          }

        })
        .catch(err => {
          commit(AUTH_ERROR, err);
          localStorage.removeItem("user-token-curator");
          reject(err);
        });
    });
  },
  [AUTH_LOGOUT]: ({ commit }) => {
    return new Promise(resolve => {
      commit(AUTH_LOGOUT);
      localStorage.removeItem("user-token-curator");
      resolve();
    });
  }
};

const mutations = {
  [AUTH_REQUEST]: state => {
    state.status = "loading";
  },
  [AUTH_SUCCESS]: (state, resp) => {
    state.status = "success";
    state.token = resp.data.data.hkey;
    state.hasLoadedOnce = true;
  },
  [AUTH_ERROR]: state => {
    state.status = "error";
    state.hasLoadedOnce = true;
  },
  [AUTH_LOGOUT]: state => {
    state.token = "";
  }
};

export default {
  state,
  getters,
  actions,
  mutations
};
