<template>
  <v-app>

    <v-app-bar app>

      <v-app-bar-nav-icon v-if="store.getters.isAuthenticated" @click.stop="drawer = !drawer">
      </v-app-bar-nav-icon>

      <v-toolbar-title >
        <span>Кабинет куратора</span>
      </v-toolbar-title>
      <v-spacer></v-spacer>

    </v-app-bar>

    <v-navigation-drawer app
      v-if="store.getters.isAuthenticated"
      v-model="drawer"
      temporary
    >
      <v-list nav dense>
        <v-list-item two-line >
          <v-list-item-content>
            <v-list-item-title>Кабинет куратора</v-list-item-title>
            <v-list-item-subtitle>{{ getProfileData('fio') }}<br/>{{ getProfileData('dgv') }}</v-list-item-subtitle>
          </v-list-item-content>
        </v-list-item>

        <v-divider></v-divider>

        <v-list-item-group
          v-model="group"
          active-class="deep-purple--text text--accent-4"
        >

          <v-list-item to="/goodsorder">
            <v-list-item-icon>
              <v-icon>mdi-cart</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Групповой заказ</v-list-item-title>
          </v-list-item>

          <v-list-item to="/cashreceive">
            <v-list-item-icon>
              <v-icon>mdi-cash</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Прием денег</v-list-item-title>
          </v-list-item>

          <v-list-item to="/photo">
            <v-list-item-icon>
              <v-icon>mdi-camera</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Фотографирование</v-list-item-title>
          </v-list-item>


          <v-list-group no-action prepend-icon="mdi-chart-bar">
            <template v-slot:activator>
                <v-list-item-title>Отчеты</v-list-item-title>
            </template>

            <v-list-item :to="{ path: 'reports', query: { report: 'balance' }}" class="pl-4">
              <v-list-item-icon>
                <v-icon>mdi-play</v-icon>
              </v-list-item-icon>
              <v-list-item-title>Состояние счетов класса</v-list-item-title>
            </v-list-item>
          </v-list-group>

          <v-list-item to="/settings">
            <v-list-item-icon>
              <v-icon>mdi-tools</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Настройки</v-list-item-title>
          </v-list-item>

          <v-list-item @click="logout">
            <v-list-item-icon>
              <v-icon>mdi-logout</v-icon>
            </v-list-item-icon>
            <v-list-item-title>Выход</v-list-item-title>
          </v-list-item>

        </v-list-item-group>

      </v-list>
    </v-navigation-drawer>




    <v-main>
      <v-container fluid>
          <router-view style="max-width: 1024px"></router-view>
      </v-container>

      <v-snackbar v-model="updateExists" :timeout=-1 bottom  left class="snack">
        Доступно обновление!
        <v-spacer />
        <v-btn dark color="#00f500" @click.native="refreshApp"> Обновить </v-btn>
        <v-btn icon @click="updateExists = false"> <v-icon>mdi-close</v-icon> </v-btn>
      </v-snackbar>

    </v-main>

    <v-footer app><v-flex style="text-align: right;">8 800 555 43 53</v-flex></v-footer>
  </v-app>
</template>

<script>
// import Personal from './components/Personal'
// import apiCall from "./utils/api";
import store from './store'
import { USER_REQUEST } from "./store/actions/user";
// import { REPORTS_REQUEST } from "./store/actions/report";
import { AUTH_LOGOUT } from "./store/actions/auth";


export default {
  name: 'App',
  components: {
    // Personal
  },
  data () {
    return {
      mini: false,
      group: 1,
      drawer: false,
      store,

      refreshing: false,
      registration: null,
      updateExists: false,
    }
  },
  created: function() {
    // Custom code to let user update the app when a new service worker is available
    document.addEventListener('swUpdated', this.showRefreshUI, {once: true})

    if (navigator.serviceWorker) {
      navigator.serviceWorker.addEventListener('controllerchange', () => {
          if (this.refreshing) return
          this.refreshing = true
          // Here the actual reload of the page occurs
          window.location.reload(true)
      })
    }

    if (store.getters.isAuthenticated) {
      store.dispatch(USER_REQUEST);
      // store.dispatch(REPORTS_REQUEST);
    }
  },
  computed: {
    webaccess: function() {
      return (store.getters.getProfile.data && store.getters.getProfile.data.data && store.getters.getProfile.data.data.tarif && store.getters.getProfile.data.data.tarif.webaccess==1)
    },
    reports: function () {
      return store.getters.getReports
    }
  },
  methods: {
    getProfileData(prop) {
      if(store.getters.getProfile.data && store.getters.getProfile.data.data)
        return store.getters.getProfile.data.data[prop];
    },
    /*
    getFoto() {
      if(store.getters.getProfile.data && store.getters.getProfile.data.photo)
        return 'data:image/jpeg;base64,' + store.getters.getProfile.data.photo;
      else
        return require(`./assets/images/nophoto.jpg`);
    },*/

    logout: function () {
        store.dispatch(AUTH_LOGOUT)
        .then(() => {
          this.$router.push('/login')
        })
    },
    showRefreshUI(e) {
        // console.log(e)
        this.registration = e.detail
        this.updateExists = true
    },
    refreshApp() {
        this.updateExists = false
        if (!this.registration || !this.registration.waiting) return
        // send message to SW to skip the waiting and activate the new SW
        this.registration.waiting.postMessage({type:'SKIP_WAITING'})
    }
  }
}
</script>