import { REPORTS_REQUEST, REPORTS_ERROR, REPORTS_SUCCESS } from "../actions/report";
import apiCall from "../../utils/api";
import Vue from "vue";

const state = { status: "", reports: [] };

const getters = {
  getReports: state => state.reports,
  isReportsLoaded: state => !!state.reports.length
};

const actions = {
  [REPORTS_REQUEST]: ({ commit }) => {
    commit(REPORTS_REQUEST);
    apiCall({ method: "getReports" })
      .then(resp => {
        commit(REPORTS_SUCCESS, resp);
      })
      .catch(() => {
        commit(REPORTS_ERROR);
      });
  },
};

const mutations = {
  [REPORTS_REQUEST]: state => {
    state.status = "loading";
  },
  [REPORTS_SUCCESS]: (state, resp) => {
    state.status = "success";
    Vue.set(state, "reports", (resp.data && resp.data.data) ? resp.data.data : []);
  },
  [REPORTS_ERROR]: state => {
    state.status = "error";
  },
};

export default {
  state,
  getters,
  actions,
  mutations
};
