import axios from 'axios';
//import HmacSha1 from 'hmac_sha1'
import hmacSHA1 from 'crypto-js/hmac-sha1';

const apiCall = ( params ) => {

  let hkey = localStorage.getItem("user-token-curator") || "";
  let idperson = localStorage.getItem("idperson-curator") || "";
  if(idperson) params.idperson = idperson;

  params = JSON.stringify(params);
  //let hmacSha1 = new HmacSha1();
  //let sign = hmacSha1.digest(hkey, params, 'utf8');
  /*
  const crypto = require('node:crypto')
  let sign = crypto.createHmac('sha1', hkey)
  .update(params)
  .digest('hex')

*/

  let sign = hmacSHA1(params, hkey).toString()

  let url = 'https://data.infoshkola.net/api/curator';
  if (process.env.NODE_ENV === 'development') url = 'http://62.109.26.51/api/curator'; //localhost

  return axios.post(url, params, {headers: {'Content-Type': 'application/json', 'sign': sign}});
}

export default apiCall;
